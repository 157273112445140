/**
 * @file Brand.tsx
 * @description Brand page
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  DialogContentText,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { Formik } from "formik";
import FieldFW from "../components/common/templates/forms/FieldFW";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import Title from "../components/common/titles/Title";
import WithNav from "../components/common/templates/WithNav";
import OnLoadWithNav from "../components/common/templates/WithNav/OnLoadWithNav";
import OnErrorWithNav from "../components/common/templates/WithNav/OnErrorWithNav";
import useStyles from "../components/common/templates/style";
import CampaignsTable from "../components/Campaigns/CampaignsTable";
import MSISDNsTable from "../components/MSISDNs/MSISDNsTable";
import { useUserContext } from "../context/UserContext";
import { validationSchema as brandValidationSchema } from "../components/Brands/common/validationSchema";
import { validationSchema as campaignValidationSchema } from "../components/Campaigns/common/validationSchema";
import { validationSchema as msisdnValidationSchema } from "../components/MSISDNs/BulkUploadProgress/validationSchema";
import brandService, { BrandType } from "../services/brandService";
import campaignService, { AntiSpoofingType, ApprovalStatus, CampaignType } from "../services/campaignService";
import msisdnService, { MSISDNType } from "../services/msisdnService";
import EditLogo, { LogoType } from "../components/common/Logo/EditLogo";
import AlertDialog from "../components/common/templates/feedback/AlertDialog";
import AlertSnackback from "../components/common/templates/feedback/AlertSnackbar";
import XlsxDropzone from "../components/Assets/XlsxDropzone";
import {
  BulkUploadError,
  BulkUploadRow,
  BulkUploadSummary,
  CampaignProperties,
  newBulkUploadError,
  setCampaignProperties
} from "../components/MSISDNs/BulkUploadProgress/BulkUploadProgress";
import BulkUploadDialog from "../components/common/templates/feedback/BulkUploadDialog/BulkUploadDialog";
import partnerService from "../services/partnerService";
import AddMSISDN from "../components/MSISDNs/AddMSISDN";
import CampaignDesigner from "../components/Campaigns/CampaignDesigner";
import PaiDiscovery from "../components/MSISDNs/PaiDiscovery";
import { compareStrings, extractErrorMessage } from "../utils/utils";
import { isAntiSpoofingFeatureEnabled } from "../utils/featureToggle";
import { invalidateBrandQueries, invalidateCampaignQueries, invalidateMsisdnQueries } from "../utils/invalidateQueries";
import * as Excel from "exceljs";

/**
 * Props
 * @typedef {{brandId: string}} Props
 */
interface Props {
  brandId?: string;
}
/**
 * Renders Brand page
 * @param props component props @see Props
 * @returns {React.Component} Brand page
 */
/**
 * State
 * @typedef {{brandAlias: string}} State
 */
interface State {
  partnerAlias?: string;
}

export default function Brand(props: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { brandId: brandIdFromUrlParams } = useParams<{ brandId: string }>();
  const { user } = useUserContext();
  const { role } = user;
  const { state } = useLocation() as { state: State };
  const [msg, setMsg] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [openAddMsisdn, setOpenAddMsisdn] = useState(false);
  const [openPaiDiscovery, setOpenPaiDiscovery] = useState(false);
  const [openAddCampaign, setOpenAddCampaign] = useState(false);

  // Bulk Upload file upload
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const [hasSelectedFile, setHasSelectedFile] = useState(false);
  const [target, setTarget] = useState<File>();

  // Bulk Upload progress
  const freshSummary = {
    newNumbers: 0,
    updatedNumbers: 0,
    unchangedNumbers: 0,
    newCampaigns: 0,
    updatedCampaigns: 0,
    unchangedCampaigns: 0,
    finalMessage: ""
  };
  const [bulkUploadSummary, setBulkUploadSummary] = useState<BulkUploadSummary>(freshSummary);
  const [bulkUploadErrors, setBulkUploadErrors] = useState<BulkUploadError[]>([]);
  const [openBulkUploadProgress, setOpenBulkUploadProgress] = useState(false);
  const [uploading, setUploading] = useState(false);

  // Bulk Upload Excel template schema
  const BRAND_ALIAS_ROW = 1;
  const BRAND_ALIAS_CELL = 2;
  const HEADER_ROWS_COUNT = 2;
  const PAI_CELL = 1;
  const FROM_CELL = 2;
  const CAMPAIGN_NAME_CELL = 3;
  const CAMPAIGN_DISPLAY_NAME_CELL = 4;
  const CAMPAIGN_DESCRIPTION_CELL = 5;

  // Reset everything when the popups are closed

  useEffect(() => {
    setBulkUploadSummary(freshSummary);
    setBulkUploadErrors([]);
  }, [openBulkUploadProgress]);

  useEffect(() => {
    setTarget(undefined);
    setHasSelectedFile(false);
  }, [openBulkUpload]);

  const variant =
    role === "vodafone-admin" || role === "partner-manager"
      ? "outlined"
      : "standard";
  const areBrandDetailsReadOnly =
    role === "vodafone-admin" || role === "partner-manager" ? false : true;
  const canEditCampaignsNumbers = 
    role === "brand-manager" || role === "partner-manager" || role === "vodafone-admin";

  let brandId: string;
  if (!props.brandId) {
    brandId = brandIdFromUrlParams as string;
  } else {
    brandId = props.brandId;
  }

  const {
    data: brand,
    isLoading: loadingBrand,
    error: brandError,
  } = useQuery<BrandType>(["brand", brandId], () => brandService.getSingle(brandId));

  const [antiSpoofingChecked, setAntiSpoofingChecked] = useState(brand?.feature_anti_spoofing_enabled);
  const handleAntiSpoofingChange = (event: any) => {
    setAntiSpoofingChecked(event.target.checked);
  };

  useEffect(() => {
    setAntiSpoofingChecked(brand?.feature_anti_spoofing_enabled);
  }, [brand]);

  const {
    data: campaigns,
    isLoading: loadingCampaigns,
    error: campaignsError,
  } = useQuery<CampaignType[]>(["campaigns", brandId], () => campaignService.getByParam({ brandId: brandId }));

  const {
    data: msisdns,
    isLoading: loadingMsisdns,
    error: msisdnsError,
  } = useQuery<MSISDNType[]>(["msisdns", brandId], () => msisdnService.getAll(brandId));

  const queryClient = useQueryClient();

  const { mutateAsync: updateBrand } = useMutation(
    async (props: any) => {
      await brandService.update(props);
    },
    {
      onSuccess: () => {
        setMsg("Brand updated!");
        setSuccess(true);
        invalidateBrandQueries(queryClient);
      }
    }
  );

  const { mutateAsync: deleteBrand } = useMutation(brandService.delete, {
    onSuccess: () => {
      setMsg("Brand deleted!");
      setSuccess(true);
      invalidateBrandQueries(queryClient);
      navigate("../..", {relative: "path"});
    }
  });

  const manageUsers = (brandAlias: string, partnerAlias?: string) => {
    if (brandAlias && partnerAlias) {
      navigate(location.pathname + "/users", {
        state: { partnerAlias: partnerAlias, brandAlias: brandAlias },
      });
    } else {
      navigate(location.pathname + "/users", {
        state: { brandAlias: brandAlias },
      });
    }
  };

  const { mutateAsync: createMSISDN } = useMutation(msisdnService.create, {
    onSuccess: () => {
      invalidateMsisdnQueries(queryClient);
      invalidateCampaignQueries(queryClient);
    }
  });

  const { mutateAsync: updateMSISDN } = useMutation(msisdnService.update, {
    onSuccess: () => {
      invalidateMsisdnQueries(queryClient);
      invalidateCampaignQueries(queryClient);
    }
  });

  const { mutateAsync: createCampaign } = useMutation(campaignService.create, {
    onSuccess: () => {
      // do not show message for bulk upload
      if (openAddCampaign) {
        setOpenAddCampaign(false);
        setMsg("Campaign created!");
        setSuccess(true);
      }
      invalidateCampaignQueries(queryClient);
      invalidateMsisdnQueries(queryClient);
    }
  });

  const { mutateAsync: updateCampaign } = useMutation(campaignService.update, {
    onSuccess: () => {
      invalidateCampaignQueries(queryClient);
      invalidateMsisdnQueries(queryClient);
    }
  });

  const bulkDownload = async () => {
    // Fetch template.
    // workbook.xlsx.readFile does not seem to work. Probably because of this:
    // https://github.com/exceljs/exceljs/issues/1168.
    // So we have to use workbook.xlsx.load instead.
    const response = await fetch("/bulk-upload-template.xlsx");
    const buffer = await response.arrayBuffer();

    const workbook = await new Excel.Workbook().xlsx.load(buffer);

    // Fill Brand Alias (cell A2)
    const instructionsWorksheet: Excel.Worksheet = workbook.getWorksheet("Instructions")!;
    const row = instructionsWorksheet.getRow(BRAND_ALIAS_ROW);
    row.getCell(BRAND_ALIAS_CELL).value = brand!.alias;
    row.commit();

    // Sort by
    // 1. Campaign Name
    // 2. PAI
    // 3. From
    const sortedMsisdns  = [...msisdns!];
    sortedMsisdns!.sort((msisdn1, msisdn2) => {
      const getCampaignName = (msisdn: MSISDNType) => campaigns!.find(c => c.id === msisdn.campaign_id)?.name;

      const result = compareStrings(getCampaignName(msisdn1), getCampaignName(msisdn2));
      if (result !== 0) return result;

      const paiResult = compareStrings(msisdn1.pai, msisdn2.pai);
      if (paiResult !== 0) return paiResult;

      return compareStrings(msisdn1.from, msisdn2.from);
    });

    // Populate Numbers and Campaigns
    const databaseWorksheet: Excel.Worksheet = workbook.getWorksheet("Database")!;

    const campaignsWithNumbers = new Set();
    sortedMsisdns!.forEach((msisdn, index) => {
      const rowNumber = index + 1 + HEADER_ROWS_COUNT; // Skip Header rows.
      const row = databaseWorksheet.getRow(rowNumber);
      const campaign = campaigns!.find(campaign => campaign.id === msisdn.campaign_id);
      if (campaign) {
        row.getCell(CAMPAIGN_NAME_CELL).value = campaign.name; // Campaign Name
        row.getCell(CAMPAIGN_DISPLAY_NAME_CELL).value = campaign.display_name; // Campaign Display Name
        row.getCell(CAMPAIGN_DESCRIPTION_CELL).value = campaign.desc; // Campaign Description
        campaignsWithNumbers.add(campaign.id);
      }
      row.getCell(PAI_CELL).value = msisdn.pai; // PAI
      row.getCell(FROM_CELL).value = msisdn.from; // From
      row.commit();
    });

    // Add campaigns without numbers
    campaigns!.filter(campaign => !campaignsWithNumbers.has(campaign.id)).sort((a, b) => compareStrings(a.name, b.name)).forEach((campaign, index) => {
      const rowNumber = sortedMsisdns.length + HEADER_ROWS_COUNT + index + 1;
      const row = databaseWorksheet.getRow(rowNumber);
      row.getCell(CAMPAIGN_NAME_CELL).value = campaign.name; // Campaign Name
      row.getCell(CAMPAIGN_DISPLAY_NAME_CELL).value = campaign.display_name; // Campaign Display Name
      row.getCell(CAMPAIGN_DESCRIPTION_CELL).value = campaign.desc; // Campaign Description
      row.commit();
    });

    let output = await workbook.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([output]));
    const link = document.createElement('a');
    link.href = url;
    link.download = "bulk_download_brand-" + brand!.alias + ".xlsx";
    link.click();
    window.URL.revokeObjectURL(url);
  };

  /** 
   * Convert a date into an ISO date string like 2023-06-23,
   * using the local time of the date.
   */
  function toIsoDate(date: Date): string {
    return date.getFullYear() + "-"
        + ("0" + (date.getMonth() + 1)).slice(-2) + "-"
        + ("0" + date.getDate()).slice(-2);
  }

  /** 
   * Push a BulkUploadError and add it to errors state
   */
  function pushBulkUploadError(errors: BulkUploadError[], newError: BulkUploadError) {
    errors.push(newError);
    setBulkUploadErrors(errors);
  }

  /**
   * Returns true if at least one of the fields in `mutableFields`
   * has a different value betweeen the two objects.
   */
  function areObjectsDifferent<T>(mutableFields: Array<keyof T>, dbEntity: any, updateObject: T): boolean {
    return mutableFields.some((field) => dbEntity[field] !== updateObject[field]);
  }

  async function createBulkUploadCampaign(campaign: {name: string, display_name: string, desc: string}) {
    const newCampaign : CampaignType = {
      name: campaign.name,
      display_name: campaign.display_name,
      desc: campaign.desc,
      brand_id: brand!.id,
      approval_status: ApprovalStatus.APPROVED,
      anti_spoofing: AntiSpoofingType.DISABLED
    }
    const result = await createCampaign(newCampaign);
    return {...newCampaign, id: result.id};
  }

  const handleDelete = () => {
    setOpenConfirm(false);
    deleteBrand(brandId).catch((err) => {
      setMsg(extractErrorMessage(err));
      setError(true);
    });
  };

  const isLoading: boolean = loadingBrand || loadingCampaigns || loadingMsisdns;
  const renderError = brandError || campaignsError || msisdnsError;

  let campaignsMap = new Map(campaigns?.map(campaign => [campaign.id as string, campaign]));

  if (isLoading) return <OnLoadWithNav />;
  if (renderError) return <OnErrorWithNav error={renderError} />;

  return (
    <WithNav>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>{brand!.name}</Title>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} display="flex">
            <Grid item xs>
              <Paper className={classes.paper}>
                <Title>Brand Details</Title>
                <Formik
                  enableReinitialize={true}
                  validateOnChange={true}
                  initialValues={{
                    id: brand!.id,
                    name: brand!.name,
                    desc: brand!.desc,
                    alias: brand!.alias,
                    signature: brand!.signature,
                    partner_id: brand!.partner_id,
                    pai_contingent: brand!.pai_contingent
                  }}
                  validationSchema={brandValidationSchema}
                  onSubmit={async (brand, { setSubmitting }) => {
                    setSubmitting(true);
                    try {
                      await updateBrand({
                        ...brand, 
                        feature_anti_spoofing_enabled: antiSpoofingChecked as boolean
                      });  
                    } catch (err: any) {
                      setError(true);
                      setMsg(extractErrorMessage(err));
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <FieldFW
                            type="text"
                            required
                            id="name"
                            name="name"
                            placeholder="Name"
                            variant={variant}
                            readonly={areBrandDetailsReadOnly}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FieldFW
                            type="text"
                            required
                            id="desc"
                            name="desc"
                            placeholder="Description"
                            variant={variant}
                            readonly={areBrandDetailsReadOnly}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FieldFW
                            type="text"
                            required
                            id="alias"
                            name="alias"
                            placeholder="Alias"
                            variant={variant}
                            readonly={true}
                          />
                        </Grid>
                        <Grid item xs={12} display="flex" alignItems="end">
                          <Grid item xs={3}>
                            <FieldFW
                              type="number"
                              required
                              id="pai_contingent"
                              name="pai_contingent"
                              placeholder="PAI Contingent"
                              variant={variant}
                              readonly={areBrandDetailsReadOnly}
                            />
                          </Grid>
                          <Grid item xs={5} marginLeft="1em">
                            {brand!.pai_contingent - new Set(msisdns!.map((number) => number.pai)).size} out of {brand!.pai_contingent} PAIs are available
                          </Grid>
                        </Grid>
                        {isAntiSpoofingFeatureEnabled() &&
                          <Grid item xs={12}>
                            <FormControlLabel
                              label="Enable Anti-Spoofing feature"
                              checked={!!antiSpoofingChecked}
                              onChange={handleAntiSpoofingChange}
                              disabled={areBrandDetailsReadOnly}
                              control={<Checkbox />}
                            />
                          </Grid>
                        }
                        {!areBrandDetailsReadOnly && (
                          <Grid item xs={12}>
                            <div className={classes.buttons}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                              >
                                Update Brand Details
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={() => setOpenConfirm(true)}
                              >
                                Delete Brand
                              </Button>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Paper>
            </Grid>
            {(brand!.logo || !areBrandDetailsReadOnly) && (
              <Grid item lg={3} md={5} sm={5}>
                <Paper className={classes.paper}>
                  <Title>Logo</Title>
                  <EditLogo
                    id={brand!.id as string}
                    type={LogoType.BRAND}
                    setError={(msg) => {
                      setMsg(msg);
                      setError(true);
                    }}
                    logoSrc={brand!.logo}
                    canChangeLogo={!areBrandDetailsReadOnly}
                  />
                </Paper>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>Campaigns</Title>

            {canEditCampaignsNumbers && (
              <Grid item xs={12}>
                <div className={classes.buttons}>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenAddCampaign(true)}
                  >
                    Add Campaign
                  </Button>

                </div>
              </Grid>
            )}

            <CampaignsTable
              data={campaigns!}
              brand={brand!}
              msisdns={msisdns!}
              setSuccessMessage={(msg) => {
                setMsg(msg);
                setSuccess(true);
              }}
              setErrorMessage={(msg) => {
                setMsg(msg);
                setError(true);
              }}
            />

          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>Numbers</Title>
            
            {canEditCampaignsNumbers && (
              <Grid item xs={12}>
                <div className={classes.buttons}>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenAddMsisdn(true)}
                  >
                    Add Number
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.tableButtons}
                    onClick={() => setOpenBulkUpload(true)}
                  >
                    Bulk Upload
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.tableButtons}
                    onClick={() => bulkDownload()}
                  >
                    Bulk Download
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.tableButtons}
                    onClick={() => setOpenPaiDiscovery(true)}
                  >
                    PAI Discovery
                  </Button>

                </div>
              </Grid>
            )}

            <MSISDNsTable
              data={msisdns!}
              campaignsMap={campaignsMap}
              setSuccessMessage={(msg) => {
                setMsg(msg);
                setSuccess(true);
              }}
              setErrorMessage={(msg) => {
                setMsg(msg);
                setError(true);
              }}
            />
          </Paper>
        </Grid>

        {canEditCampaignsNumbers && (
          <AddMSISDN
            open={openAddMsisdn}
            handleClickClose={() => setOpenAddMsisdn(false)}
            brandId={brandId}
          />
        )}

        {canEditCampaignsNumbers && (
          <PaiDiscovery
            brandId={brandId}
            open={openPaiDiscovery}
            handleClickClose={() => setOpenPaiDiscovery(false)}
            setErrorMsg={(msg) => {
              setMsg(msg);
              setError(true);
            }}
          />
        )}

        {canEditCampaignsNumbers && (
          <CampaignDesigner
            brandId={brandId}
            saveCampaign={createCampaign}
            open={openAddCampaign}
            setOpen={setOpenAddCampaign}
            setErrorMsg={(msg) => {
              setMsg(msg);
              setError(true);
            }}
          />
        )}

        <Dialog
          open={openBulkUpload}
          onClose={() => setOpenBulkUpload(false)}
          aria-labelledby="form-dialog-title"
          style={{ width: "100%" }}
        >

          <DialogTitle id="form-dialog-title">Bulk Upload</DialogTitle>
          <DialogContent>

            <DialogContentText>
              <Typography paragraph>
                Bulk Upload adds a list of Numbers to this Brand.
              </Typography>
              <Typography paragraph>
                Download <Link to="/bulk-upload-template.xlsx" target="_blank" download>this Excel template</Link>, 
                follow the instructions on the first worksheet, enter the Numbers on the second worksheet, 
                and finally upload the updated Excel document in this dialog.
              </Typography>
            </DialogContentText>

            <Formik
              enableReinitialize={true}
              initialValues={{
                file: target as File,
              }}
              onSubmit={async (data, { setSubmitting }) => {
                setOpenBulkUploadProgress(true);
                setSubmitting(true);
                setUploading(true);

                const buffer = await (target as File).arrayBuffer();
                let workbook;
                try {
                  workbook = await new Excel.Workbook().xlsx.load(buffer);
                } catch (e) {
                  rejectBulkUploadWithMessage("Invalid XLSX file");
                  return;
                }

                // Check if Brand alias of the input matches with selected Brand.
                // The Brand Alias should be in the first tab, cell A2.

                const instructionsWorksheet: Excel.Worksheet = workbook.getWorksheet("Instructions")!;
                if (!instructionsWorksheet) {
                  rejectBulkUploadWithMessage("Error reading Instructions sheet");
                  return;
                }
                // second row, first cell
                const inputBrandAlias = instructionsWorksheet.getRow(BRAND_ALIAS_ROW).getCell(BRAND_ALIAS_CELL).value;

                if (inputBrandAlias !== brand!.alias) {
                  rejectBulkUploadWithMessage("Brand alias in Excel file does not match the alias of selected Brand");
                  return;
                }

                const rows: BulkUploadRow[] = [];
                const databaseWorksheet: Excel.Worksheet = workbook.getWorksheet("Database")!;
                if (!databaseWorksheet) {
                  rejectBulkUploadWithMessage("Error reading Database sheet");
                  return;
                }

                databaseWorksheet.eachRow((row, rowNumber) => {
                  // Skip header rows
                  if (rowNumber <= HEADER_ROWS_COUNT) {
                    return;
                  }
                  rows.push({
                    campaignName: row.getCell(CAMPAIGN_NAME_CELL).text || undefined,
                    campaignDisplayName: row.getCell(CAMPAIGN_DISPLAY_NAME_CELL).text || undefined,
                    campaignDescription: row.getCell(CAMPAIGN_DESCRIPTION_CELL).text || undefined,
                    pai: row.getCell(PAI_CELL).text || undefined,
                    from: row.getCell(FROM_CELL).text || undefined,
                    excelRowNumber: rowNumber
                  });
                });

                let summary: BulkUploadSummary = freshSummary;
                let bulkUploadErrors: BulkUploadError[] = [];

                const uniquePais: Set<string> = new Set(msisdns!.map((number) => number.pai));

                const paiAndFromCombinations: Set<string> = new Set();

                // Map from Campaign Name to Campaign properties.
                const campaignPropertiesMap: Map<string, CampaignProperties> = new Map();

                let hasValidationError: boolean = false;

                for (let i = 0; i < rows.length ; i++) {
                  const row: BulkUploadRow = rows[i];

                  const excelRowNumber = row.excelRowNumber!;
                  const campaignName = row.campaignName;
                  const campaignDisplayName = row.campaignDisplayName;
                  const campaignDescription = row.campaignDescription;
                  const pai = row.pai;
                  const from = row.from;

                  /*
                    * Row validations, i.e. validations involving limitations within a row.
                    * These can generate errors which will be shown at the end in each row's result,
                    * in the Bulk Upload summary.
                    * A validation error will reject the Bulk Upload before attempting
                    * any updates on the server side.
                    */

                  // if any Campaign Property is defined, "Campaign Name" must be defined too
                  if ((campaignDisplayName || campaignDescription) && !campaignName) {
                    pushBulkUploadError(
                      bulkUploadErrors,
                      newBulkUploadError("A row containing any Campaign property must also have a Campaign Name", excelRowNumber, row)
                    );
                    hasValidationError = true;
                    continue;
                  }

                  // if FROM is defined, PAI cannot be empty
                  if (from && !pai) {
                    pushBulkUploadError(
                      bulkUploadErrors,
                      newBulkUploadError("PAI is required when From is defined", excelRowNumber, row)
                    );
                    hasValidationError = true;
                    continue;
                  }

                  /*
                    * "Global" validations, i.e. validations involving limitations among all rows.
                    * These can generate fatal errors, which will reject the Bulk Upload before attempting
                    * any updates on the server side.
                    */

                  if (pai) {
                    // Check for duplicated "PAI"/"From" combinations.
                    // Multiple rows with empty "PAI" and empty "From" are valid.
                    // This is needed for creating/updating multiple Campaigns that have no Numbers assigned.
                    // We only need to worry about PAI/From combinations if PAI is defined.
                    // Otherwise:
                    // 1. PAI and From are NOT defined: row with empty number.
                    // 2. PAI is NOT defined and From is defined: row will generate a "PAI is required when FROM is defined" error.
                    const combination = pai + (from ? "/" + from : "");
                    if (paiAndFromCombinations.has(combination)) {
                      rejectBulkUploadWithMessage("There must be no duplicate PAI/From combinations in Excel file: " + combination);
                      return;
                    } else {
                      paiAndFromCombinations.add(combination);
                    }

                    try {
                      // Validate PAI and From fields.
                      // To reuse the Yup objects, we need to do some preparation of the validated object:
                      // 1. Pass is_pai_prefix and is_from_prefix.
                      // 2. Remove "*" from "pai" and "from".
                      const isPaiPrefix: boolean = pai.endsWith("*");
                      const isFromPrefix: boolean = from?.endsWith("*") || false;
                      await msisdnValidationSchema.validate({
                        is_pai_prefix: isPaiPrefix,
                        pai: isPaiPrefix ? pai.slice(0, -1) : pai,
                        is_from_prefix: isFromPrefix,
                        from: isFromPrefix ? from!.slice(0, -1) : (from || undefined),
                      });
                    } catch (validationError: any) {
                      pushBulkUploadError(
                        bulkUploadErrors,
                        newBulkUploadError(validationError.message, excelRowNumber, row)
                      );
                      hasValidationError = true;
                      continue;
                    }

                    uniquePais.add(pai);
                  }

                  // We need to merge the campaign properties from all rows.
                  // For each Campaign Name value and each Campaign Property column,
                  // it is not allowed to have 2 different, non-empty campaign property values.
                  if (campaignName) {
                    let properties;
                    if (campaignPropertiesMap.has(campaignName)) {
                      properties = setCampaignProperties(campaignPropertiesMap.get(campaignName)!, row);
                    } else {
                      properties = setCampaignProperties({campaignDisplayName: null, campaignDescription: null}, row);
                      campaignPropertiesMap.set(campaignName, properties!);
                    }
                    if (!properties) {
                      rejectBulkUploadWithMessage("Campaign named '" + campaignName + "' cannot have a property with two different non-empty values");
                      return;
                    }
                  }
                }

                // Count the number of new PAIs up front.
                // The whole bulk upload fails if it would exhaust the PAI contingent.
                if (uniquePais.size > brand!.pai_contingent) {
                  rejectBulkUploadWithMessage("Bulk Upload was rejected because it would exhaust the PAI Contingent");
                  return;
                }

                // Validate Campaign fields
                for (const mapEntry of Array.from(campaignPropertiesMap.entries())) {
                  const campaignName = mapEntry[0];
                  const campaignProperties = mapEntry[1];

                  let existentCampaign = campaigns!.find((campaign) => campaign.name === campaignName);
                  try {
                    // To reuse the Yup objects, we need to do some preparation of the validated object:
                    // 1. Pass campaign_anti_spoofing_enabled = false. We do not care about anti-spoofing here.
                    // 2. Already set the values that will be used in create/update.
                    // 3. If the campaign does not exist and no "Display Name" is set in any row for this campaign,
                    // then "Campaign Name" must be validated as a display name, because in this case the "Campaign Name" will be used as "Display Name".
                    let validatedDisplayName;
                    let validatedDescription;
                    if (!existentCampaign) {
                      validatedDisplayName = campaignProperties.campaignDisplayName || campaignName;
                      const currentDate = toIsoDate(new Date());
                      validatedDescription = campaignProperties.campaignDescription || "Bulk upload on " + currentDate;
                    } else {
                      validatedDisplayName = campaignProperties.campaignDisplayName || existentCampaign.display_name;
                      validatedDescription = campaignProperties.campaignDescription || existentCampaign.desc;
                    }
                    const validatedObject = {
                      name: campaignName,
                      display_name: validatedDisplayName,
                      desc: validatedDescription,
                      campaign_anti_spoofing_enabled: false
                    };
                    await campaignValidationSchema.validate(validatedObject);

                    // Set fields that will be used to create/update the campaign
                    setCampaignProperties(campaignProperties, {
                      campaignDisplayName: validatedObject.display_name,
                      campaignDescription: validatedObject.desc,
                    });

                  } catch (validationError: any) {
                    rejectBulkUploadWithMessage("Campaign named " + campaignName + " has invalid fields: " + validationError.message);
                    return;
                  }
                };

                // Check if any error was detected during the client-side validation.
                if (hasValidationError) {
                  setBulkUploadSummary({...summary, finalMessage: "❌ Upload rejected!"});
                  setUploading(false);
                  setSubmitting(false);
                  setOpenBulkUpload(false);
                  return;
                }

                // Map from Campaign Name to CampaignType to store campaigns
                // that were already processed during this Bulk Upload operation.
                const processedCampaigns: Map<string, CampaignType> = new Map();

                // Set containing the names of failed campaigns.
                // Rows with these campaigns should not be processed.
                const failedCampaigns = new Set();

                for (let i = 0; i < rows.length ; i++) {
                  const row: BulkUploadRow = rows[i];

                  const excelRowNumber = row.excelRowNumber!;
                  const campaignName = row.campaignName;
                  const pai = row.pai;
                  const from = row.from;

                  let rowCampaign: CampaignType | undefined = undefined;
                  if (campaignName) {

                    if (failedCampaigns.has(campaignName)) {
                      pushBulkUploadError(
                        bulkUploadErrors,
                        newBulkUploadError(
                          "Row was rejected. Campaign named '" + campaignName + "' failed to be created in a previous row/attempt.",
                          excelRowNumber, row
                        )
                      );
                      continue;
                    }

                    const campaignProperties: CampaignProperties = campaignPropertiesMap.get(campaignName)!;
                    rowCampaign = processedCampaigns.get(campaignName);

                    // Create or update campaign if it has not been processed yet
                    if (!rowCampaign) {

                      let existentCampaign = campaigns!.find((campaign) => campaign.name === campaignName);

                      if (!existentCampaign) {

                        // Campaign needs to be created

                        try {
                          rowCampaign = await createBulkUploadCampaign({
                            name: campaignName,
                            display_name: campaignProperties.campaignDisplayName!,
                            desc: campaignProperties.campaignDescription!
                          });
                          summary.newCampaigns++;
                        } catch (err: any) {
                          pushBulkUploadError(
                            bulkUploadErrors,
                            newBulkUploadError("Failed to create campaign '" + campaignName + "': " + extractErrorMessage(err), excelRowNumber, row)
                          );
                          // Mark as processed and add it to failedCampaigns set.
                          // Rows with this campaign should not be processed.
                          processedCampaigns.set(campaignName, rowCampaign!);
                          failedCampaigns.add(campaignName);
                          continue;
                        }

                      } else {

                        // Campaign exists. Check if it needs to be updated.

                        const updatedCampaign = {
                          ...existentCampaign,
                          name: campaignName,
                          display_name: campaignProperties.campaignDisplayName!,
                          desc: campaignProperties.campaignDescription!
                        };

                        try {
                          rowCampaign = updatedCampaign;

                          const mutableFields: Array<keyof CampaignType> = ["name", "display_name", "desc"];
                          if (areObjectsDifferent(mutableFields, existentCampaign, updatedCampaign)) {
                            await updateCampaign(updatedCampaign);
                            summary.updatedCampaigns++;
                          } else {
                            summary.unchangedCampaigns++;
                          }
                        } catch (err: any) {
                          // Campaign will be marked as processed and we continue with the row.
                          // Numbers still can/should be assigned to the Campaign.
                          pushBulkUploadError(
                            bulkUploadErrors,
                            newBulkUploadError(
                              "Failed to update campaign '" + campaignName + "': " + extractErrorMessage(err) + ". Numbers will still be assigned to it.",
                              excelRowNumber, row
                            )
                          );
                        }

                      }

                      processedCampaigns.set(campaignName, rowCampaign!);
                    }
                  }

                  if (!pai) {
                    // Row with empty number. No need to do anything else.
                    setBulkUploadSummary(summary);
                    continue;
                  }

                  // Check if Number already exists for selected Brand
                  let existentNumber: MSISDNType | undefined = undefined;

                  for (const existentMsisdn of msisdns!) {
                    // Note == instead of === because we want null to match undefined
                    if (pai == existentMsisdn.pai && from == existentMsisdn.from) {
                      existentNumber = existentMsisdn;
                    }
                  }

                  if (!existentNumber) {

                    // Number does not exist

                    let campaignId: string | undefined = rowCampaign?.id;

                    // Create the number with the associated campaign
                    let msisdn : MSISDNType = {
                      pai: pai,
                      from: from? from : undefined,
                      campaign_id: campaignId,
                      brand_id: brand!.id,
                    }

                    try {
                      await createMSISDN(msisdn);
                    } catch (err: any) {
                      pushBulkUploadError(
                        bulkUploadErrors,
                        newBulkUploadError(extractErrorMessage(err), excelRowNumber, row)
                      );
                      continue;
                    }
                    summary.newNumbers++;

                  } else {

                    // Number already exists

                    if (existentNumber.campaign_id != rowCampaign?.id) {
                      // note != instead of !==
                      // we do not want an update if both are empty/null/undefined

                      // Campaigns (of input and existent number) are different, 
                      // number needs to be updated

                      let campaignId: string | undefined = rowCampaign?.id;

                      let msisdn : MSISDNType = {
                        ...existentNumber,
                        // We need "from" to be undefined instead of null/empty
                        from: existentNumber.from ? existentNumber.from : undefined,
                        campaign_id: campaignId,
                      };
                      try {
                        await updateMSISDN(msisdn);
                      } catch (err: any) {
                        pushBulkUploadError(
                          bulkUploadErrors,
                          newBulkUploadError(extractErrorMessage(err), excelRowNumber, row)
                        );
                        continue;
                      }
                      summary.updatedNumbers++;
                    } else {
                      // number is already in the correct state, do nothing
                      summary.unchangedNumbers++;
                    }

                  }
                  setBulkUploadSummary(summary);
                }
                setBulkUploadSummary({
                  ...summary,
                  finalMessage: bulkUploadErrors.length > 0 ? "⚠️ Upload completed with error(s)!" : "✅ Upload complete!"
                });
                setUploading(false);
                setSubmitting(false);
                setOpenBulkUpload(false);

                function rejectBulkUploadWithMessage(message: string) {
                  setMsg(message);
                  setError(true);
                  setUploading(false);
                  setOpenBulkUploadProgress(false);
                  setSubmitting(false);
                  setOpenBulkUpload(false);
                }
              }}
            >
              {({ isSubmitting, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                    <XlsxDropzone
                      target={target}
                      setTarget={setTarget}
                      setHasSelectedFile={setHasSelectedFile}
                    />
                      {}
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button type="submit" disabled={isSubmitting || !hasSelectedFile} color="primary">
                      Upload
                    </Button>
                    <Button onClick={() => setOpenBulkUpload(false)} color="secondary">
                      Cancel
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>

        {(role === "partner-manager" || role === "vodafone-admin") && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>Users</Title>
              <Button
                onClick={
                  state && state.partnerAlias
                    ? () =>
                      manageUsers(
                        brand!.alias,
                        state.partnerAlias as string
                      )
                    : async () => 
                      manageUsers(
                        brand!.alias,
                        await partnerService.getAlias(brand!.partner_id)
                      )
                }
              >
                Manage User Permissions
              </Button>
            </Paper>
          </Grid>
        )}
        {(role === "partner-manager" || role === "vodafone-admin" || role === "vodafone-reporter") && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>Reports</Title>
              <Button
                onClick={() => navigate(location.pathname + "/dashboard", {
                  state: { brandId: brand!.id },
                })}
              >
                Dashboard
              </Button>
              <Button
                onClick={() => navigate(location.pathname + "/chartDesigner", {
                  state: { brandId: brand!.id },
                })}
              >
                Chart Designer
              </Button>
            </Paper>
          </Grid>
        )}
      </Grid>
      <BulkUploadDialog
        open={openBulkUploadProgress}
        setOpen={setOpenBulkUploadProgress}
        bulkUploadSummary={bulkUploadSummary} 
        bulkUploadErrors={bulkUploadErrors}
        uploading={uploading}
      />
      <AlertDialog
        resourceName="this Brand"
        open={openConfirm}
        setOpen={setOpenConfirm}
        handleYes={handleDelete}
      />
      <AlertSnackback
        message={msg}
        type="success"
        open={success}
        setOpen={setSuccess}
      />
      <AlertSnackback
        message={msg}
        type="error"
        open={error}
        setOpen={setError}
      />
    </WithNav>
  );
}
