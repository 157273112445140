/**
 * @file BulkUploadProgress.tsx
 * @description BulkUploadProgress component to show the results of a Bulk Upload operation
 * @author Rafael Mello
 */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import useStyles from "../common/style";

export type BulkUploadSummary = {
  newNumbers: number,
  updatedNumbers: number,
  unchangedNumbers: number,
  newCampaigns: number;
  updatedCampaigns: number;
  unchangedCampaigns: number,
  finalMessage: string
}

export type BulkUploadRow = {
  campaignName?: string,
  campaignDisplayName?: string,
  campaignDescription?: string,
  pai?: string,
  from?: string,
  excelRowNumber?: number
}

export type BulkUploadError = {
  row: BulkUploadRow
  rowNumber: number,
  errorMsg: string
}

export function newBulkUploadError(errorMsg: string, rowNumber: number, row: BulkUploadRow): BulkUploadError {
  return {
    errorMsg: errorMsg,
    rowNumber: rowNumber,
    row: row
  }
}

export type CampaignProperties = {
  campaignDisplayName: string | null,
  campaignDescription: string | null
}

/**
 * Set the value of campaign properties contained in a row to existing properties.
 * 
 * Return true if the properties in the given row can be set to the existent properties.
 * A property can only be set if the existent value of this property is undefined or has the same value.
 */
export function setCampaignProperties(properties: CampaignProperties, row: BulkUploadRow): CampaignProperties | undefined {
  for (const key of Object.keys(properties)) {
    const propKey = key as keyof CampaignProperties;
    const property = properties[propKey];
    const newProperty = row[propKey];
    if (newProperty) {
      if (property && property !== newProperty) {
        return undefined;
      }
      properties[propKey] = newProperty;
    }
  }
  return properties;
}

interface Props {
  summary: BulkUploadSummary,
  errors: BulkUploadError[]
}

export default function BulkUploadProgress(props: Props) {
  const classes = useStyles();
  return (
    <>
      <Table className={classes.bulkUploadSummary}>
        <TableBody>
          <TableRow
            key="newNumbers"
          >
            <TableCell>New Numbers</TableCell>
            <TableCell>{props.summary.newNumbers}</TableCell>
          </TableRow>
          <TableRow
            key="updatedNumbers"
          >
            <TableCell>Updated Numbers</TableCell>
            <TableCell>{props.summary.updatedNumbers}</TableCell>
          </TableRow>
          <TableRow
            key="unchangedNumbers"
          >
            <TableCell>Unchanged Numbers</TableCell>
            <TableCell>{props.summary.unchangedNumbers}</TableCell>
          </TableRow>
          <TableRow
            key="newCampaigns"
          >
            <TableCell>New Campaigns</TableCell>
            <TableCell>{props.summary.newCampaigns}</TableCell>
          </TableRow>
          <TableRow
            key="updatedCampaigns"
          >
            <TableCell>Updated Campaigns</TableCell>
            <TableCell>{props.summary.updatedCampaigns}</TableCell>
          </TableRow>
          <TableRow
            key="unchangedCampaigns"
          >
            <TableCell>Unchanged Campaigns</TableCell>
            <TableCell>{props.summary.unchangedCampaigns}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      { props.errors.length > 0 &&
        (
          <Table className={classes.bulkUploadErrorTable}>
            <TableHead>
              <TableRow>
                <TableCell>PAI</TableCell>
                <TableCell>From</TableCell>
                <TableCell>Campaign Name</TableCell>
                <TableCell>Campaign Display Name</TableCell>
                <TableCell>Campaign Description</TableCell>
                <TableCell>Error</TableCell>
                <TableCell>Row Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.errors.map((error) => (
                <TableRow
                  key={(error.row.campaignName || "") + (error.row.pai || "") + (error.row.from || "") + error.errorMsg}
                >
                  <TableCell>{error.row.pai}</TableCell>
                  <TableCell>{error.row.from}</TableCell>
                  <TableCell>{error.row.campaignName}</TableCell>
                  <TableCell>{error.row.campaignDisplayName}</TableCell>
                  <TableCell>{error.row.campaignDescription}</TableCell>
                  <TableCell>{error.errorMsg}</TableCell>
                  <TableCell>{error.rowNumber}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )
      }
    </>
  );
}
